import Chartjs from 'chart.js'

class Chart extends React.Component {

  chartObject = null
  data = null
  canvasRef = React.createRef()

  componentDidMount() {
    Chartjs.Tooltip.positioners.miniclient = function(tooltips, position) {
      if (Array.isArray(tooltips)) {
        var canvas = tooltips[0]._chart.canvas
        var tooltip = tooltips[0]._view
        if (position.x + tooltip.x > canvas.clientWidth) {
          return {x: canvas.clientWidth - tooltip.x, y: position.y}
        } else if (position.x < 80) {
          return {x: tooltip.x, y: position.y}
        }
      }
      return {x: position.x, y: position.y}
    }
    this.data = MC.extend(true, {}, this.props.data.param)
    this.initializeChart(this.props)
  }

  componentDidUpdate() {
    if (JSON.stringify(this.data) != JSON.stringify(this.props.data.param)) {
      this.data = MC.extend(true, {}, this.props.data.param)
      this.destroyChart()
      this.initializeChart(this.props)
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  destroyChart() {
    this.chartObject && this.chartObject.destroy();
  }

  initializeChart(props) {
    let defaultColors = ['#36A2EB', '#FF6384', '#FFCE56', '#33C379', '#0063E4', '#DD3B3B', '#F0D193', '#94F686', '#0088B7', '#A90C13', '#DCB927', '#ABCB00']
    let type = props.type
    let legPos = MC.getFieldParamValue(props.data.param, '@legendPosition')
    let legend = {}
    legend.display = (legPos == 'none' ? false : true)
    if (['right', 'left', 'top', 'bottom', 'none'].indexOf(legPos) < 0) {
      legPos = 'top'
    }
    legend.position = MC.isNull(legPos) || legPos == 'none' ? 'top' : legPos
    if (type == 'pie') {
      let items = MC.getFieldParamValue(props.data.param, 'values') || []
      let values = []
      let labels = []
      let colors = []
      for (let item of MC.asArray(items)) {
        values.push(item['@amount'])
        labels.push(item['@name'])
        colors.push(item['@color'])
      }
      labels = MC.isNull(labels) ? null : labels
      colors = MC.isNull(colors) ? defaultColors : colors
      if (Array.isArray(values) && values.length > colors.length) {
        colors = this.fillColors(colors, values.length)
      }
      let data = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors
        }]
      }
      let cutoutPercentage = MC.getFieldParamValue(props.data.param, '@cutoutPercentage')
      if (MC.isNull(cutoutPercentage)) {
        cutoutPercentage = 0
      }
      this.chartObject = new Chartjs(this.canvasRef.current, {
        type: 'pie',
        data: data,
        options: {
          legend: legend,
          cutoutPercentage: cutoutPercentage,
          tooltips: {
            position: 'miniclient',
            caretSize: 0
          }
        }
      })
    } else if (type == 'basic') {
      let labels = MC.getFieldParamValue(props.data.param, 'xAxis/c')
      labels = MC.isNull(labels) ? null : MC.asArray(labels)
      let ysoftmin = 0
      ysoftmin = MC.getFieldParamValue(props.data.param, 'yAxis/softMin')
      ysoftmin = MC.isNull(ysoftmin) ? null : MC.asArray(ysoftmin)
      let ysoftmax = 0
      ysoftmax = MC.getFieldParamValue(props.data.param, 'yAxis/softMax')
      ysoftmax = MC.isNull(ysoftmax) ? null : MC.asArray(ysoftmax)
      let ysoftminSecondary = 0
      ysoftminSecondary = MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMin')
      ysoftminSecondary = MC.isNull(ysoftminSecondary) ? null : MC.asArray(ysoftminSecondary)
      let ysoftmaxSecondary = 0
      ysoftmaxSecondary = MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMax')
      ysoftmaxSecondary = MC.isNull(ysoftmaxSecondary) ? null : MC.asArray(ysoftmaxSecondary)
      let timeUnit = MC.getFieldParamValue(props.data.param, 'xAxis/timeUnit')
      let stacked = MC.getFieldParamBooleanValue(props.data.param, '@stacked') || false
      let type = MC.getFieldParamValue(props.data.param, '@chartType')
      let series = MC.getFieldParamValue(props.data.param, 'series') || []
      series = MC.asArray(series)
      let useSecondaryYaxis = false
      let datasets = []
      for (let i = 0; i < series.length ; i++) {
        let serie = series[i]
        let dataset = {}
        dataset.type = serie['@type'] ? serie['@type'] : 'bar'
        dataset.data = serie.d
        dataset.label = serie['@name'] ? serie['@name'] : ''
        let color = serie['@color'] ? serie['@color'] : defaultColors[i % defaultColors.length]
        dataset.borderColor = color
        dataset.backgroundColor = color
        if (dataset.type == 'line') {
          if (["default", "monotone"].indexOf(serie['@interpolationMode']) > -1) {
            dataset.cubicInterpolationMode = serie['@interpolationMode']
          } else {
            dataset.lineTension = 0
          }
          if (["origin", "start", "end"].indexOf(serie['@fill']) > -1) {
            dataset.fill = serie['@fill']
            if (serie['@backgroundColor']) {
              dataset.backgroundColor = serie['@backgroundColor']
            }
          } else {
            dataset.fill = false
          }
        }
        if (true === serie['@isSecondary']) {
          dataset.yAxisID = 'yaxis-secondary'
          useSecondaryYaxis = true
        } else {
          dataset.yAxisID = 'yaxis-primary'
        }
        dataset.borderWidth = 2
        datasets.push(dataset)
      }
      const yAxes = []
      yAxes.push({id: 'yaxis-primary', stacked: stacked, position: 'left', ticks: {suggestedMin: ysoftmin, suggestedMax: ysoftmax} })
      if (useSecondaryYaxis) {
        yAxes.push({id: 'yaxis-secondary', stacked: stacked, position: 'right', ticks: {suggestedMin: ysoftminSecondary, suggestedMax: ysoftmaxSecondary} })
      }
      type = (type == 'line' ? 'line' : 'bar')
      let xAxes = {stacked: stacked}
      if (!MC.isNull(timeUnit)) {
        xAxes.type = 'time'
        xAxes.distribution = 'linear'
        let time = {}
        time.unit = timeUnit
        let timeMin = MC.getFieldParamValue(props.data.param, 'xAxis/timeMin')
        if (!MC.isNull(timeMin)) {
          time.min = timeMin
        }
        let timeMax = MC.getFieldParamValue(props.data.param, 'xAxis/timeMax')
        if (!MC.isNull(timeMax)) {
          time.max = timeMax
        }
        xAxes.time = time
      }
      this.chartObject = new Chartjs(this.canvasRef.current, {
        type: type,
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          legend: legend,
          responsive: true,
          scales: {
            xAxes: [xAxes],
            yAxes: yAxes
          },
          tooltips: {
            mode: "x",
            intersect: false,
            position: "nearest",
            backgroundColor: "#FFFFFF",
            titleFontColor: "#000000",
            titleFontStyle: "normal",
            titleMarginBottom: 10,
            bodyFontColor: "#516C85",
            bodySpacing: 5,
            borderColor: "#E3EDED",
            borderWidth: 1,
            xPadding: 15,
            yPadding: 15,
            displayColors: false,
            callbacks: {
              labelTextColor:function(tooltipItem, chart) {
                return chart.data.datasets[tooltipItem.datasetIndex].borderColor
              }
            },
            filter: function(item, data) {
              data = data.datasets[item.datasetIndex].data[item.index]
              return !isNaN(data) && data !== null
            }
          }
        }
      })
    }
  }

  fillColors(colors, size) {
    var ncolors = [];
    for (var i = 0; i < size; i++) {
      if (i<colors.length) {
        ncolors.push(colors[i]);
      } else {
        ncolors.push(colors[i % colors.length]);
      }
    }
    return ncolors;
  }

  render() {
    return <canvas data-widget-i-name={this.props.data.id} ref={this.canvasRef}/>
  }

}

MC.registerReactRomponent('chart', Chart)